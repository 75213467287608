import React from "react";
import Lozko from "../components/Lozko";

const InLoveWithDesign = () => {
  const sample = ["Melangerie", "Metis", "Trench"];
  const productName = "In Love with Design®";
  const FOLDER_NAME = "/images/inLoveWithDesign";
  const desc = "Łóżko In Love with Design® to projekt, który odzwierciedla naszą pasję do designu i pracochłonność, jaką wkładamy w każdy szczegół. Naszym celem było stworzenie wyjątkowego łóżka dla miłośników designu, dla tych wymagających klientów, którzy poszukują czegoś naprawdę nietuzinkowego i nietypowego. Nasz produkt wyróżnia się owalnym kształtem zarówno korpusu, jak i zagłówka. Rama łóżka jest oparta na solidnych 18-centymetrowych nóżkach, co nadaje mu stabilność i elegancję. Każdy detal został dopracowany, aby zapewnić nie tylko estetyczny wygląd, ale także trwałość na lata. In Love with Design jest idealnym wyborem dla pięknych, nowoczesnych pomieszczeń o dużej przestrzeni. To łóżko jest prawdziwym dziełem sztuki. Mebel jest opatentowany i posiada prawa autorskie.";
  const dim = {
    h: 128,
    l: 232,
    w: "193/213/233",
  };
  const features = [
    "wzór przemysłowy chroniony przez Urząd Patentowy",
    "regulacja wysokości zagłówka",
    "do wyboru dwa rodzaje drewnianych nóg o wysokości 20cm",
    "zalecany stelaż: Favorit",
    "rekomendowana tkanina: Trench",
    "możliwość zamówienia w nietypowych wymiarach",
    "zwróć uwagę na możliwość wyboru nóg (proste lub skośne)"
  ]
  const carousel = [
    `${FOLDER_NAME}/colors/1.jpg`,
    `${FOLDER_NAME}/colors/2.jpg`,
    `${FOLDER_NAME}/colors/3.jpg`,
    `${FOLDER_NAME}/colors/4.jpg`,
    `${FOLDER_NAME}/colors/5.jpg`,
    `${FOLDER_NAME}/colors/6.jpg`,
    `${FOLDER_NAME}/colors/7.jpg`,
  ]

  return (
    <Lozko
    FOLDER_NAME={FOLDER_NAME}
    name={productName}
    desc={desc}
    dim={dim}
    features={features}
    sample={sample}
    carousel={carousel}/>
  );
};

export default InLoveWithDesign;
